import { Routes, Route, Navigate } from "react-router";
import Tools from "./views/Tools";
import { RouteMaster } from "./config/route.config";
import Terms from "./views/Pages/Terms";
import Contactus from "./views/Pages/Contactus";
import About from "./views/Pages/AboutUs";
import PrivacyPolicy from "./views/Pages/PrivacyPolicy";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tools" ></Navigate>}></Route>
      <Route path="/tnc" Component = {Terms} />
      <Route path="/about" Component = {About} />
      <Route path="/privacypolicy" Component = {PrivacyPolicy} />
      <Route path="/contactus" Component = {Contactus} />
      <Route path="/tools" Component={Tools} >
        {RouteMaster.map((item,index)=>{
          return(
            <Route key={index} path={item.path} element={<item.component keywords = {item.keywords} title={item.name} description={item.description}/>}></Route>
          )
        })}
      </Route>
      <Route path="*" element={<Navigate to="/tools" ></Navigate>}></Route>
    </Routes>
  );
};

export default AppRoutes;
