import { Typography } from "@mui/material";

const ToolTipText = () => {
  return (
    <Typography variant="body">
      Tips: <br />
      1. Pin codewarp tab in your browser for better productivity. <br />
      2. Recently used tools can be used to have quick access to the tools you
      frequently need. <br />
      3. You can use ContactUs page to request tools or give feedback.
    </Typography>
  );
};

export default ToolTipText;
