import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Header from "./Header";
import "../../../css/common.css";
import { useState } from "react";
import CopyButton from "../../../lib/components/CopyButton";
import signjwt from "../../../lib/logic/jwt";
import { enqueueSnackbar } from "notistack";
import AdComponent from "../../../lib/components/AdComponent";

const JWT = (props) => {
  const { title, description, keywords } = { ...props };
  const [isLoading, setIsLoading] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const [signKey, setSignKey] = useState("");
  const [hashType, setHashType] = useState("HS256");
  const [out, setOut] = useState("");

  const handlechange = () => {
    let obj = {};
    
    try {
      obj = JSON.parse(originalText);
    } catch (ex) {
      enqueueSnackbar("Input JSON is not Correct!", { variant: "error" });
      setOut("");
      return;
    }
    if (!signKey) {
      enqueueSnackbar("Signing Key is required!", { variant: "error" });
      setOut("");
      return;
    }

    setIsLoading(true);
    signjwt(obj, signKey, hashType)
      .then((res) => {
        if (res && res.data && res.data.results) {
          setOut(res.data.results);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Some Error Occurred", { variant: "error" });
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setHashType(e.target.value);
  };

  return (
    <Box height="100%">
      <Header
        keywords={keywords}
        title={title}
        description={description}
      ></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container>
          <Grid item xs={5}>
            <Stack spacing={1.5}>
              <TextField
                multiline
                minRows={6}
                maxRows={10}
                sx={{ width: "100%" }}
                label="JSON"
                value={originalText}
                onChange={(e) => setOriginalText(e.target.value)}
              ></TextField>

              <TextField
                sx={{ width: "100%", pt: 1 }}
                label="Signing Key"
                value={signKey}
                onChange={(e) => setSignKey(e.target.value)}
              ></TextField>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center" spacing={2}>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel>Algorithm</InputLabel>
                <Select
                  value={hashType}
                  label="Hashing Algorithm"
                  onChange={handleChange}
                >
                  <MenuItem value={"HS256"}>HS256</MenuItem>
                  <MenuItem value={"HS384"}>HS384</MenuItem>
                  <MenuItem value={"HS512"}>HS512</MenuItem>
                </Select>
              </FormControl>
              <Button
                className="btnwidth"
                variant="contained"
                onClick={handlechange}
                disabled={isLoading}
              >
                Encode
              </Button>
              {isLoading? <CircularProgress /> : null }
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <TextField
              multiline
              label="Output"
              minRows={6}
              maxRows={10}
              sx={{ width: "100%" }}
              value={out}
            ></TextField>
            <CopyButton text={out} />
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default JWT;
