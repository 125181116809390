import { Box, Stack } from "@mui/system";
import Footer from "../Footer/Footer";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [type, setType] = useState("");
  const [comment, setComment] = useState("");

  const handleChange = (e, type) => {
    if (type === "formtype") {
      setType(e.target.value);
    }
    if (type === "comment") {
      setComment(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!type) {
      enqueueSnackbar("Please Select Type", { variant: "error" });
      return;
    }
    if (!comment) {
      enqueueSnackbar("Please type some comment", { variant: "error" });
      return;
    }
    let url = `https://docs.google.com/forms/d/e/1FAIpQLSfsohjdyVPGAqKzNQ2-Rw8eTNk-b2ZjtMO5LBA7lrcLaVWE3Q/formResponse?&submit=Submit?usp=pp_url&entry.181014053=${type}&entry.2134269814=${comment}`;
    fetch(url, { mode: "no-cors" })
      .then((res) => {
        enqueueSnackbar("Submit Successful", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Some Error Occurred, please try again later", {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      <Toolbar></Toolbar>
      <Box sx={{ height: "100%", backgroundColor: "#ffffff" }}>
        <Stack alignItems="center" sx={{ pt: 2, pr: 5, pl: 5, pb: 2 }}>
          <Paper
            sx={{
              backgroundColor: "#ffffff",
              pt: 2,
              pl: 2,
              pr: 2,
              pb: 2,
              borderRadius: 2,
              textAlign: "center",
            }}
            elevation={2}
          >
            <Typography variant="h2">Contact US</Typography>

            <Box sx={{ minHeight: "100vh", pt: 5, pr: 10, pl: 10 }}>
              <Typography variant="body1" gutterBottom>
                We welcome your feedback, suggestions, and tool requests to
                enhance your experience on our website! Please feel free to
                reach out to us at Qtools10@gmail.com for any inquiries or
                assistance you may need. Additionally, you can use the form
                below to submit your new tool requests or share your suggestions
                with us. We appreciate your input and strive to continually
                improve our services to better serve you. Thank you for choosing
                CodeWarp.in!
              </Typography>

              <Box sx={{ mt: 10 }}>
                <FormControl>
                  <InputLabel id="formType">Select Type</InputLabel>
                  <Select
                    value={type}
                    variant="outlined"
                    onChange={(e) => handleChange(e, "formtype")}
                    sx={{ width: "40em" }}
                    labelId="formType"
                    label="Select Type*"
                  >
                    <MenuItem value={"Feedback"}>Feedback</MenuItem>
                    <MenuItem value={"ToolRequest"}>Tool Request</MenuItem>
                  </Select>
                  <TextField
                    value={comment}
                    sx={{ width: "40em", mt: 2 }}
                    multiline
                    minRows={6}
                    maxRows={10}
                    onChange={(e) => handleChange(e, "comment")}
                    label="Comment*"
                  ></TextField>
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    size="large"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Box>
              <SnackbarProvider
                autoHideDuration={2000}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              />
            </Box>
          </Paper>
        </Stack>
      </Box>
      <Divider></Divider>
      <Footer></Footer>
    </Box>
  );
};

export default Contactus;
