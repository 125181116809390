import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import charactercount from "../../../lib/logic/charactercount";
import wordcount from "../../../lib/logic/wordcount";
import "../../../css/common.css";
import Header from "./Header";
import AdComponent from "../../../lib/components/AdComponent";

const CharacterCount = (props) => {
  const { title, description, keywords } = { ...props };
  const [res, setRes] = useState({ character:0, words:0});
  const [withoutSpace, setWithoutSpace] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const handleclick = () => {
    setRes({
      ...res,
      character : charactercount(originalText,withoutSpace),
      words : wordcount(originalText)
    });
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              className="inputM"
              value={originalText}
              label="Input"
              placeholder="Input text here"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={20}
              onChange={(e) => {
                setOriginalText(e.target.value);
              }}
            ></TextField>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={withoutSpace} onChange={(e)=>setWithoutSpace(e.target.checked)} />} label="Ignore all whitespace characters" />
            </FormGroup>
          </Grid>

          <Grid textAlign="center" item xs={2}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handleclick}
            >
              Count
            </Button>
          </Grid>

          <Grid item xs={5}>
            <Typography>Charater Count : {res.character}</Typography>
            <Typography>Word Count : {res.words}</Typography>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default CharacterCount;
