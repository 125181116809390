import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import App from "./App";

const theme = createTheme({
  palette: {
    primary : {
      main : "#0081a7"
    },
    palettecolor1 : {
      main : "#f07167"
    },
    palettecolor2 : {
      main : "#fed9b7"
    },
    palettecolor3 : {
      main : "#fdfcdc"
    },
    palettecolor4 : {
      main : "#00afb9"
    }
  },
});

const rootElement = document.getElementById("root");

const SnapWrap = (
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>

);

if (rootElement.hasChildNodes()) {
  hydrate(SnapWrap, rootElement);
} else {
  render(SnapWrap, rootElement);
}