import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import base64decode from "../../../lib/logic/base64decode";
import base64encode from "../../../lib/logic/base64encode";
import "../../../css/common.css";
import Header from "./Header";
import { Stack } from "@mui/system";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const Base64Encode = (props) => {
  const { title, description, keywords } = { ...props };
  const [res, setRes] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [isError, setIserror] = useState(false);
  const handleclick = (type) => {
    setIserror(false);

    if(type===1){
      setRes(base64encode(originalText));
    }
    else if(type===2){
    try {
      let decoded = base64decode(originalText);
      setRes(decoded);
    } catch (exception) {
      setIserror(true);
      setRes("");
    }
  }
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              className="inputM"
              value={originalText}
              label="Input"
              placeholder="Input text here"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              onChange={(e) => {
                setOriginalText(e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid item xs={2}>
            <Stack alignItems="center" spacing={2}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={()=>handleclick(1)}
            >
              Encode &gt;
            </Button>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={()=>handleclick(2)}
            >
              Decode &gt;
            </Button>
            </Stack>
          </Grid>

          <Grid item xs={5}>
            <TextField
              className="inputM"
              label="Output"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              value={res}
              error={isError}
              helperText={
                isError ? "There is some error in given string" : null
              }
            ></TextField>
            <CopyButton text={res}/>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default Base64Encode;
