import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import Header from "./Header";
import "../../../css/common.css";
import { useState } from "react";
import textreplace from "../../../lib/logic/textreplace";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const TextReplace = (props) => {
  const { title, description, keywords } = { ...props };
  const [originalText, setOriginalText] = useState("");
  const [replaceWhat, setReplaceWhat] = useState("");
  const [replaceWith, setReplaceWith] = useState("");
  const [out, setOut] = useState("");

  const handlechange = () => {
    let res = textreplace(originalText, replaceWhat, replaceWith);
    setOut(res);
  };

  return (
    <Box height="100%">
      <Header
        keywords={keywords}
        title={title}
        description={description}
      ></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container>
          <Grid item xs={5}>
            <Stack spacing={1.5}>
              <TextField
                multiline
                minRows={6}
                maxRows={10}
                sx={{ width: "100%" }}
                label="Input"
                value={originalText}
                onChange={(e) => setOriginalText(e.target.value)}
              ></TextField>

              <TextField
                sx={{ width: "100%", pt: 1 }}
                label="Replace What"
                value={replaceWhat}
                onChange={(e) => setReplaceWhat(e.target.value)}
              ></TextField>

              <TextField
                sx={{ width: "100%", pt: 1 }}
                label="Replace With"
                value={replaceWith}
                onChange={(e) => setReplaceWith(e.target.value)}
              ></TextField>
            </Stack>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handlechange}
            >
              Replace
            </Button>
          </Grid>
          <Grid item xs={5}>
            <TextField
              multiline
              label="Output"
              minRows={6}
              maxRows={10}
              sx={{ width: "100%" }}
              value={out}
            ></TextField>
            <CopyButton text={out} />
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default TextReplace;
