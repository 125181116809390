import CryptoJS from "crypto-js";
export default function shaHash(input="",type){
    if(input===""){
        return "";
    }

    switch(type){
        case "sha1": {
            return CryptoJS.SHA1(input);
        }
        case "sha224": {
            return CryptoJS.SHA224(input);
        }
        case "sha256": {
            return CryptoJS.SHA256(input);
        }
        case "sha3": {
            return CryptoJS.SHA3(input);
        }
        case "sha384": {
            return CryptoJS.SHA384(input);
        }
        case "sha512": {
            return CryptoJS.SHA512(input);
        }
        default : {
            return "";
        }
    }

}