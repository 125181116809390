import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Stack spacing={2} alignItems="center" sx={{ pt: 5, pb: 5 }}>
      <Stack direction="row" spacing={5}>
        <Typography variant="overline" >
          <Button to="/tools" size="small" LinkComponent={Link}>Home</Button>
        </Typography>

        <Typography variant="overline" >
        <Button to="/about" size="small" LinkComponent={Link}>About Us</Button>
        </Typography>

        <Typography variant="overline" >
        <Button to="/privacypolicy" size="small" LinkComponent={Link}>Privacy Policy</Button>
        </Typography>

        <Typography variant="overline">
        <Button to="/tnc" size="small" LinkComponent={Link}>TnC</Button>
        </Typography>

        <Typography variant="overline">
        <Button to="/contactus" size="small" LinkComponent={Link}>Contact Us</Button>
        </Typography>
      </Stack>
      <Typography variant="caption" display="block">
        ©️ codewarp.in
      </Typography>
    </Stack>
  );
};
export default Footer;
