import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import spaceremover from "../../../lib/logic/spaceremover";
import "../../../css/common.css";
import Header from "./Header";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const SpaceRemover = (props) => {
  const { title, description, keywords } = { ...props };
  const [res, setRes] = useState("");
  const [originalText, setOriginalText] = useState("");
  const handleclick = () => {
    setRes(spaceremover(originalText));
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              className="inputM"
              value={originalText}
              label="Input"
              placeholder="Input text here"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              onChange={(e) => {
                setOriginalText(e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid textAlign="center" item xs={2}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handleclick}
            >
              Remove
            </Button>
          </Grid>

          <Grid item xs={5}>
            <TextField
              className="inputM"
              label="Output"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              value={res}
            ></TextField>
            <CopyButton text={res}/>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default SpaceRemover;
