import { HelpOutline } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ToolTipText from "../../../lib/components/ToolTip";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from "@emotion/styled";

const Recents = (props) => {

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const { recents } = { ...props };
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{width:"97%"}}>
          <Typography variant="h6" noWrap component="div" sx={{ pr: 1 }}>
            Recently Used Tools
          </Typography>
        </Box>
        <Box sx={{width:"3%"}}>
          <CustomWidthTooltip sx={{maxWidth:"500px"}}  title={<ToolTipText/>}>
            <HelpOutline sx={{ alignItems: "center", mt:1, scale:"1.2" }} />
          </CustomWidthTooltip>
        </Box>
      </Box>
      <Box height={60} width="100%" display="flex" alignItems="center">
        <Stack direction="row" spacing={2}>
          {recents.map((item, index) => {
            return (
              <Button
                size="small"
                variant="contained"
                key={index}
                color={`palettecolor${(item.id % 4) + 1}`}
                component={RouterLink}
                to={item.route}
                sx={{ whiteSpace: "nowrap" }}
              >
                {item.name}
              </Button>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default Recents;
