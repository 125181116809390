import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import "../../../css/common.css";
import Header from "./Header";
import generateguid from "../../../lib/logic/guidgenerator";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const GUIDGenerator = (props) => {
  const { title, description, keywords } = { ...props };
  const [res, setRes] = useState("");
  const handleclick = () => {
      setRes(generateguid());
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              className="inputM"
              value={res}
              variant="outlined"
              multiline
              InputProps={{ readOnly : true }}
              minRows={6}
              maxRows={10}
            ></TextField>
            <CopyButton text={res}/>
          </Grid>

          <Grid textAlign="center" item xs={2}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handleclick}
            >
              Generate
            </Button>
          </Grid>

          <Grid item xs={5}>
            
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default GUIDGenerator;
