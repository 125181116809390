export default function upsidedown(input) {
  let map = new Map([
    ["A", "Ɐ"],
    ["B", "ᗺ"],
    ["C", "Ɔ"],
    ["D", "ᗡ"],
    ["E", "Ǝ"],
    ["F", "Ⅎ"],
    ["G", "⅁"],
    ["H", "H"],
    ["I", "I"],
    ["J", "ᒋ"],
    ["K", "ꓘ"],
    ["L", "⅂"],
    ["M", "ꟽ"],
    ["N", "N"],
    ["O", "O"],
    ["P", "Ԁ"],
    ["Q", "ტ"],
    ["R", "ᴚ"],
    ["S", "S"],
    ["T", "Ʇ"],
    ["U", "Ո"],
    ["V", "Λ"],
    ["W", "M"],
    ["X", "X"],
    ["Y", "⅄"],
    ["Z", "Z"],
    ["a", "ɐ"],
    ["b", "q"],
    ["c", "ɔ"],
    ["d", "p"],
    ["e", "ǝ"],
    ["f", "ɟ"],
    ["g", "ƃ"],
    ["h", "ɥ"],
    ["i", "ᴉ"],
    ["j", "ɾ"],
    ["k", "ʞ"],
    ["l", "l"],
    ["m", "ɯ"],
    ["n", "u"],
    ["o", "o"],
    ["p", "d"],
    ["q", "b"],
    ["r", "ɹ"],
    ["s", "s"],
    ["t", "ʇ"],
    ["u", "n"],
    ["v", "ʌ"],
    ["w", "ʍ"],
    ["x", "x"],
    ["y", "ʎ"],
    ["z", "z"],
    ["1", "⇂"],
    ["2", "ᘔ"],
    ["3", "Ɛ"],
    ["4", "߈"],
    ["5", "ဌ"],
    ["6", "9"],
    ["7", "ㄥ"],
    ["8", "8"],
    ["9", "6"],
    ["0", "0"],
    ["Ɐ", "A"],
    ["ᗺ", "B"],
    ["Ɔ", "C"],
    ["ᗡ", "D"],
    ["Ǝ", "E"],
    ["Ⅎ", "F"],
    ["⅁", "G"],
    ["H", "H"],
    ["I", "I"],
    ["ᒋ", "J"],
    ["ꓘ", "K"],
    ["⅂", "L"],
    ["ꟽ", "M"],
    ["N", "N"],
    ["O", "O"],
    ["Ԁ", "P"],
    ["ტ", "Q"],
    ["ᴚ", "R"],
    ["S", "S"],
    ["Ʇ", "T"],
    ["Ո", "U"],
    ["Λ", "V"],
    ["M", "W"],
    ["X", "X"],
    ["⅄", "Y"],
    ["Z", "Z"],
    ["ɐ", "a"],
    ["q", "b"],
    ["ɔ", "c"],
    ["p", "d"],
    ["ǝ", "e"],
    ["ɟ", "f"],
    ["ƃ", "g"],
    ["ɥ", "h"],
    ["ᴉ", "i"],
    ["ɾ", "j"],
    ["ʞ", "k"],
    ["l", "l"],
    ["ɯ", "m"],
    ["u", "n"],
    ["o", "o"],
    ["d", "p"],
    ["b", "q"],
    ["ɹ", "r"],
    ["s", "s"],
    ["ʇ", "t"],
    ["n", "u"],
    ["ʌ", "v"],
    ["ʍ", "w"],
    ["x", "x"],
    ["ʎ", "y"],
    ["z", "z"],
    ["⇂", "1"],
    ["ᘔ", "2"],
    ["Ɛ", "3"],
    ["߈", "4"],
    ["ဌ", "5"],
    ["9", "6"],
    ["ㄥ", "7"],
    ["8", "8"],
    ["6", "9"],
    ["0", "0"],
    [" ", " "],
    ["\n", "\n"],
    ["\r", "\r"],
    ["\t", "\t"]
  ]);

  let res = [];

  for (let i = 0; i < input.length; i++) {
    res.push(map.get(input[i]));
  }

  return res.reverse().join("");
}
