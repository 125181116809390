import { List } from "@mui/material";
import TreeNode from "./TreeNode";

const JsonTree = (props) => {

  const { data } = { ...props };
  return (
    <List>
      <TreeNode nodename={"root"} node={data} />
    </List>
  );
};

export default JsonTree;
