import { Box } from "@mui/system";
import AppRoutes from "./Routes";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <Box>
      <Helmet>
      <meta name="description" content="Discover a suite of powerful text-based tools and coding utilities on CodeWarp.in From text replace and space remover to base64 encoder/decoder and hash calculators, streamline your development workflow. Plus, features like case converters and split string functionalities. Visit us now!" />
      </Helmet>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, maxHeight: "60px" }}
        elevation={0}
      >
        <Toolbar>
          <Link to={"/tools"}>
            <img
              src="/images/logo.svg"
              alt="logo"
              style={{ maxWidth: "60px", marginRight: "10px" }}
            />
          </Link>
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="h5"
            noWrap
            component="div"
          >
            CODEWARP.IN
          </Typography>
        </Toolbar>
      </AppBar>
      <AppRoutes />
    </Box>
  );
};

export default App;
