import { ContentCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const CopyButton = (props) => {


  const handleClick = () => {
    navigator.clipboard.writeText(props.text);
    enqueueSnackbar("Copied!");
  };
  

  return (
    <>
      <IconButton onClick={handleClick}>
        <Tooltip title="Copy">
          <ContentCopy />
        </Tooltip>
      </IconButton>
      <SnackbarProvider
      autoHideDuration={2000}
      anchorOrigin={{horizontal:"right", vertical:"bottom"}}
      />
    </>
  );
};

export default CopyButton;
