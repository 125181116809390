import { Box, Stack, Typography } from "@mui/material";

const NoToolSelected = () => {
  return (
    <Stack alignItems="center">
      <Box>
        <img style={{height:"400px", width:"400px"}} alt="Empty" src="/images/Empty.svg" />
      </Box>
      <Typography variant="body2" sx={{ pt: 2 }}>
        No tool Selected, please select One!
      </Typography>
    </Stack>
  );
};

export default NoToolSelected;
