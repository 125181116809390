import { useEffect } from "react";
import {
  AdSlotCodeWarpHorizontal,
  AdSlotCodeWarpfixed,
  showAds,
} from "../../config/app.config";
import { Box } from "@mui/material";

const AdComponent = (props) => {
  const { fixed } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <>
      {showAds===1 ? fixed ? (
        <Box
        width={"100%"}
          sx={{
            alignContent: "center",
            textAlign: "center",
            mt: 2,
            mb: 2,
          }}
          >
        <ins
          class="adsbygoogle"
          style={{ display: "inline-block", width: "828px", height: "90px" }}
          data-ad-client="ca-pub-1446947731277913"
          data-ad-slot={AdSlotCodeWarpfixed}
        ></ins>
        </Box>
      ) : (
        <Box
          width={"100%"}
          sx={{
            alignContent: "center",
            textAlign: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-1446947731277913"
            data-ad-slot={AdSlotCodeWarpHorizontal}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Box>
      ) : null}
    </>
  );
};

export default AdComponent;
