import { Box, Button, Grid, Tab, Tabs, TextField } from "@mui/material";
import { useState } from "react";
import jsonformatter from "../../../lib/logic/jsonformatter";
import "../../../css/common.css";
import Header from "./Header";
import JsonTree from "../../../lib/components/JsonTree";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const JsonFormatter = (props) => {
  const { title, description, keywords } = { ...props };
  const [tabValue, setTabValue] = useState(0);
  const [res, setRes] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [isError, setIserror] = useState(false);

    const [a, setA] = useState({});

  const handleclick = () => {
    try {
      setIserror(false);
      setRes(jsonformatter(originalText));
      setA(JSON.parse(originalText));
    } catch (exception) {
      setIserror(true);
      setRes("");
    }
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={4.5}>
            <TextField
              className="inputM"
              value={originalText}
              label="Input"
              placeholder="Input text here"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              onChange={(e) => {
                setOriginalText(e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid textAlign="center" item xs={1.5}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handleclick}
            >
              Format
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Tabs
              sx={{ mt: "-50px" }}
              value={tabValue}
              onChange={(e, tabNumber) => {
                setTabValue(tabNumber);
              }}
            >
              <Tab label="Format" />
              {/* <Tab label="Viewer" /> */}
            </Tabs>
            <Box sx={{ mt: "10px" }} hidden={tabValue !== 0}>
              <TextField
                className="inputM"
                variant="outlined"
                multiline
                minRows={6}
                maxRows={20}
                fullWidth
                value={res}
                error={isError}
                helperText={
                  isError ? "Given string is not correct JSON" : null
                }
              ></TextField>
              <CopyButton text={res}/>
            </Box>
            <Box sx={{ mt: "10px" }} hidden={tabValue !== 1}>
              <JsonTree data = {a} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default JsonFormatter;
