import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import "../../../css/common.css";
import Header from "./Header";
import lowercase from "../../../lib/logic/lowercase";
import uppercase from "../../../lib/logic/uppercase";
import upsidedown from "../../../lib/logic/upsidedown";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const CaseConnverter = (props) => {
  const { title, description, keywords } = { ...props };
  const [res, setRes] = useState("");
  const [originalText, setOriginalText] = useState("");
  const handleclick = (type) => {
    if(type===1){
      setRes(lowercase(originalText));
    }
    else if(type===2){
      setRes(uppercase(originalText));
    }
    else if(type===3){
      setRes(upsidedown(originalText));
    }
  };
  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              className="inputM"
              value={originalText}
              label="Input"
              placeholder="Input text here"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              onChange={(e) => {
                setOriginalText(e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid item xs={2}>
            <Stack alignItems="center" spacing={2}>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={()=>handleclick(1)}
            >
              Lowercase
            </Button>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={()=>handleclick(2)}
            >
              Uppercase
            </Button>
            <Button
              className="btnwidth"
              variant="contained"
              onClick={()=>handleclick(3)}
            >
              Flip Text
            </Button>
            </Stack>
          </Grid>

          <Grid item xs={5}>
            <TextField
              className="inputM"
              label="Output"
              variant="outlined"
              multiline
              minRows={6}
              maxRows={15}
              value={res}
            ></TextField>
            <CopyButton text={res}/>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default CaseConnverter;
