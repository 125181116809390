import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Recents from "./Recents";
import { Outlet } from "react-router";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "../Footer/Footer";

const Tools = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [recents, setRecents] = useState([]);
  const handleRecents = (item) => {
    let temp = recents.filter((x) => x.id !== item.id);
    temp.push(item);
    if (temp.length > 6) {
      temp = temp.slice(-6);
    }
    setRecents(temp);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar handleRecents={handleRecents} />
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Toolbar />
        <Recents recents={recents}></Recents>
        <Divider></Divider>
        <Box sx={{ pr: 2, pt: 1, minHeight: "90vh" }}>
          <Outlet></Outlet>
        </Box>
        <Divider></Divider>
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default Tools;
