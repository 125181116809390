import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import Header from "./Header";
import "../../../css/common.css";
import { useState } from "react";
import splitstring from "../../../lib/logic/splitstring";
import CopyButton from "../../../lib/components/CopyButton";
import AdComponent from "../../../lib/components/AdComponent";

const SplitString = (props) => {
  const { title, description, keywords } = { ...props };
  const [originalText, setOriginalText] = useState("");
  const [delimiter, setDelimiter] = useState("");
  const [out, setOut] = useState("");

  const handlechange = () => {
    let res = splitstring(originalText,delimiter);
    setOut(res);
  };

  return (
    <Box height="100%">
      <Header keywords={keywords} title={title} description={description}></Header>
      <AdComponent fixed={true} />
      <Box height="100%" display="flex" alignItems="top" paddingTop={5}>
        <Grid container>
          <Grid item xs={5}>
            <Stack spacing={1.5}>
              <TextField
                multiline
                minRows={6}
                maxRows={15}
                sx={{ width: "100%" }}
                label="Input"
                value={originalText}
                onChange={(e) => setOriginalText(e.target.value)}
              ></TextField>

              <TextField
                sx={{ width: "100%", pt: 1 }}
                label="Delimiter"
                value={delimiter}
                onChange={(e) => setDelimiter(e.target.value)}
              ></TextField>

            </Stack>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Button
              className="btnwidth"
              variant="contained"
              onClick={handlechange}
            >
              Split
            </Button>
          </Grid>
          <Grid item xs={5}>
            <TextField
              multiline
              label="Output"
              minRows={6}
              maxRows={15}
              sx={{ width: "100%" }}
              value={out}
            ></TextField>
            <CopyButton text={out}/>
          </Grid>
        </Grid>
      </Box>
      <AdComponent fixed={false} />
    </Box>
  );
};

export default SplitString;
