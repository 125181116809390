import { Box, Stack } from "@mui/system";
import Footer from "../Footer/Footer";
import { Divider, Paper, Toolbar, Typography } from "@mui/material";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Toolbar></Toolbar>
      <Box sx={{ height: "100%", backgroundColor: "#ffffff" }}>
        <Stack alignItems="center" sx={{ pt: 2, pr: 5, pl: 5, pb: 2 }}>
          <Paper
            sx={{
              backgroundColor: "#ffffff",
              pt: 2,
              pl: 2,
              pr: 2,
              pb: 2,
              borderRadius: 2,
              textAlign: "center",
            }}
            elevation={2}
          >
            <Typography variant="h2">About US</Typography>

            <Box sx={{ minHeight: "100vh", pt: 5, pr: 20, pl: 20 }}>
              <Typography variant="h4">Welcome To Codewarp.in</Typography>
              <br />
              <Typography variant="body1" gutterBottom>
                Codewarp.in is a Professional Online text and coding tools
                Platform. Here we will only provide you with interesting content
                that you will enjoy very much. We are committed to providing you
                the best of Online text and coding tools, with a focus on
                reliability and providing text and coding tools for better
                productivity. we strive to turn our passion for Online text and
                coding tools into a thriving website. We hope you enjoy our
                Online text and coding tools as much as we enjoy giving them to
                you.
                <br />
                <br />We will keep on posting such valuable and knowledgeable
                information on our Website for all of you. Your love and support
                matters a lot.
              </Typography>
            </Box>
          </Paper>
        </Stack>
      </Box>
      <Divider></Divider>
      <Footer></Footer>
    </Box>
  );
};

export default About;
