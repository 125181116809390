import axios from 'axios';
import * as API_Service from '../../config/api.service';

export default function signjwt(input={},key,algorithm){

     let data = {
        payload : input,
        signingKey : key,
        algorithm : algorithm
     };
     let url = '/tools/encodejwt';

     return API_Service.Post(url,data);
}