import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  TextField,
  Toolbar,
} from "@mui/material";
import { NavLinks, ToolCategoryMaster } from "../../../config/route.config";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";

const drawerWidth = 240;
const Sidebar = (props) => {
  const [searchFilter, setSearchFilter] = useState("");

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ pt: 2, pl: 1, pr: 1, pb: 1.5 }}>
          <TextField
            size="small"
            label="Search tools"
            onChange={(e) => setSearchFilter(e.target.value)}
            value={searchFilter}
          />
        </Box>
        <Divider />
        <List>
          {ToolCategoryMaster.map((item, index) => {
            return (
              <Box key={index} pt="10px" pb="20px">
                <Divider>{item.Name}</Divider>
                {NavLinks
                .filter((x) => x.categoryId === item.Id)
                  .filter(
                    (x) =>
                      x.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >
                      -1
                  )
                  .map((item, index) => {
                    return (
                      <ListItemButton
                        key={item.id}
                        component={RouterLink}
                        to={item.route}
                        onClick={() => props.handleRecents(item)}
                      >
                        {item.name}
                      </ListItemButton>
                    );
                  })}
              </Box>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
