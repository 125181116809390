import { Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppTitle } from "../../../config/app.config";

const Header = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title} | {AppTitle}</title>
        <meta name="description" content={props.description} ></meta>
        <meta name="keywords" content={props.keywords} ></meta>
      </Helmet>
      <Stack spacing={1.5}>
        <Typography variant="h5">{props.title}</Typography>
        <Typography variant="body2">{props.description}</Typography>
      </Stack>
    </>
  );
};
export default Header;
