import { Box, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";

const TreeNode = ({ nodename, node }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const renderNode = (key, value) => {
    if (typeof value === "object" && value !== null) {
      return (
        <>
          <TreeNode nodename={key} node={value} />
        </>
      );
    }
    return ( typeof value==="string"?
    <ListItem key={key}>{`"${key}" : "${value}"`}</ListItem>
    :
    <ListItem key={key}>{`"${key}" : ${value}`}</ListItem>
    )
  };

  return (
    <Box>
      <Typography onClick={handleToggle} style={{ cursor: "pointer" }}>
        {nodename}
        {Array.isArray(node) ? " []" : " {}"}
        {expanded ? "-" : "+"}
      </Typography>
      {Object.entries(node).map(([key, value]) => {
        return expanded ? renderNode(key, value) : null;
      })}
    </Box>
  );
};

export default TreeNode;
