import {
  Base64EncodeDecode,
  CaseConnverter,
  CharacterCount,
  GUIDDenerator,
  JWT,
  JsonFormatter,
  MD5Hash,
  NoToolSelected,
  SHAhash,
  SpaceRemover,
  SplitString,
  TextReplace,
} from "../views/Tools/Components";

export const RouteMaster = [
  {
    id: 0,
    name: "No Tool Selected",
    route: "/tools",
    path: "",
    description :"",
    component: NoToolSelected,
  },
  {
    id: 1,
    name: "Space Remove",
    route: "/tools/spaceremover",
    path: "spaceremover",
    description :"This tool can be used to remove whitespaces from any text",
    categoryId : 1,
    keywords : "online space remover, space remove online, remove whitespaces",
    component: SpaceRemover,
  },
  {
    id: 2,
    name: "Text Replace",
    route: "/tools/textreplace",
    path: "textreplace",
    description :"This tool can be used to replace the any given text with any other desired text in the given input",
    keywords : "online text replace, text replace online, replace text",
    categoryId : 1,
    component: TextReplace,
  },
  {
    id: 3,
    name: "Text Case Converter",
    route: "/tools/caseconverter",
    path: "caseconverter",
    description :"Convert text to all lowecase, uppercase or flip text",
    keywords : "uppercase online, lowercase online, flip text, upside down text online",
    categoryId : 1,
    component: CaseConnverter,
  },
  {
    id: 4,
    name: "Character/Word Count",
    route: "/tools/charactercount",
    path: "charactercount",
    description :"Count the characters/words in the string, by default all the whitespace charaters will be counted",
    keywords : "character count, count words",
    categoryId : 1,
    component: CharacterCount,
  },
  {
    id: 5,
    name: "Split String/CSV to new Line",
    route: "/tools/splitstring",
    path: "splitstring",
    description :"This tool can be used to split any string based on the delimiter provided, this tool can be used to split CSV using ',' as the deleimiter",
    keywords : "split string online, csv to new line",
    categoryId : 1,
    component: SplitString,
  },
  {
    id: 6,
    name: "Base64 Encode/Decode",
    route: "/tools/base64encode",
    path: "base64encode",
    description :"Encode in base64 or Decode from base64",
    keywords : "base64 encode online, base64 decode online",
    categoryId : 2,
    component: Base64EncodeDecode,
  },
  {
    id: 7,
    name: "GUID Generate",
    route: "/tools/guidgenerator",
    path: "guidgenerator",
    description :"Generate a new GUID. NOTE:GUID is not always unique, always verify before proceeding",
    keywords : "generate GUID online, uuid online",
    categoryId : 2,
    component: GUIDDenerator,
  },
  {
    id: 8,
    name: "JSON Formatter",
    route: "/tools/jsonformatter",
    path: "jsonformatter",
    description :"This a tool for formatting a JSON string",
    keywords : "format json online, json formatter",
    categoryId : 2,
    component: JsonFormatter,
  },
  {
    id: 9,
    name: "MD5 Hash",
    route: "/tools/md5hash",
    path: "md5hash",
    description :"This a tool for genrating MD5 Hash from a string",
    keywords : "md5 hash online, generate md5 hash online",
    categoryId : 2,
    component: MD5Hash,
  },
  {
    id: 10,
    name: "SHA Hash",
    route: "/tools/shahash",
    path: "shahash",
    description :"This a tool for genrating different SHA Hash from a string",
    keywords : "sha hash online, generate sha hash online",
    categoryId : 2,
    component: SHAhash,
  },
  {
    id: 11,
    name: "JWT Encode",
    route: "/tools/jwt",
    path: "jwt",
    description :"Encode JWT online",
    categoryId : 2,
    component: JWT,
  }
];


export const ToolCategoryMaster = [
  {
    Id : 1,
    Name : "Text Tools"
  },
  {
    Id : 2,
    Name : "Coding Tools"
  }

]

export const NavLinks = RouteMaster.filter(x=>x.id!==0).map((item, index) => {
  return {
    id: item.id,
    name: item.name,
    route: item.route,
    categoryId : item.categoryId
  };
});
